.input-mapping-slider {
    opacity: 1 !important;
    cursor: default !important;
}

.input-mapping-slider .bp4-slider-progress:nth-child( -n + 2 ) {
    background-color: #D50032;
}

.input-mapping-slider .bp4-slider-progress:nth-child( 3 ) {
    background-color: #006747;
}

.hidden-handle > .bp4-slider-handle {
    display: none;
}
